import { makeLogService } from '../../data/services/common/log/log/log-service-factory';
import { makeRouterService } from '../../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../../environments/make-environment';

export function addEventListenerClicksOnScreen() {
  if (makeEnvironment().isProd) {
    document.body.classList.add('no-cursor');
  }

  const logService = makeLogService();
  const routerService = makeRouterService();

  let clickCountAdmin = 0;
  let clickCountCursor = 0;

  document.body.addEventListener('click', (event) => {
    const x = event.clientX;
    const y = event.clientY;

    const centerPage = globalThis.screen.availWidth / 2;

    if (y > 0 && y <= 100) {
      if (x > 0 && x <= centerPage) {
        /**
         * Canto superior esquerdo - Admin area
         */
        logService.info('Hit admin');
        clickCountAdmin++;
      } else {
        /**
         * Canto superior direito - Libera Mouse
         */
        logService.info('Hit cursor');
        clickCountCursor++;
      }
    } else {
      clickCountAdmin = 0;
      clickCountCursor = 0;
    }

    if (globalThis.clickTimeout) {
      clearTimeout(globalThis.clickTimeout);
    }

    globalThis.clickTimeout = setTimeout(() => {
      clickCountAdmin = 0;
      clickCountCursor = 0;
    }, 6e3);

    if (clickCountAdmin === 10) {
      globalThis.isAdminOpen = true;

      routerService.navigate('admin');
    }

    if (
      document.body.classList.contains('no-cursor') &&
      clickCountCursor === 10
    ) {
      clickCountCursor = 0;
      document.body.classList.replace('no-cursor', 'cursor');
    }

    if (document.body.classList.contains('cursor') && clickCountCursor === 10) {
      clickCountCursor = 0;
      document.body.classList.replace('cursor', 'no-cursor');
    }
  });
}
