import { makeKeyValueRepository } from '../../../repositories/key-value/key-value-repository-factory';
import { makeEventService } from '../event/event-service-factory';
import { makeLogService } from '../log/log/log-service-factory';
import { ITranslateService } from './translate-service';
import { TranslateServiceImpl } from './translate-service-impl';

let translateService: ITranslateService;

export function makeTranslateService() {
  if (!translateService) {
    translateService = new TranslateServiceImpl(
      makeLogService(),
      makeEventService(),
      makeKeyValueRepository(),
    );
  }
  return translateService;
}
