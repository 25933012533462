import { Injector } from '@angular/core';
import { Router } from '@angular/router';

export class CustomInjector {
  static router: Router;
  static injector: Injector;

  static setup(injector: Injector) {
    this.injector = injector;
    this.router = injector.get(Router);
  }
}
