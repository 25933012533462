import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { SuperDynamicComponent } from './../../../helpers/super-dynamic-component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent extends SuperDynamicComponent {
  constructor(private injector: Injector) {
    super(injector);
  }
}
