import { Schedules } from '../schedule/schedule';
import { Space } from '../space/space';

export class Medias {
  id: string;
  status?: string;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  filename: string;
  type: string;
  duration: number;
  entityId: string;
  schedules: Schedules[];
  space: Space;
  spaceId: string;
}
