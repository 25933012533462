import { IBannerBaseboardRepository } from '../../../data/repositories/banner-baseboard/banner-baseboard-repository';
import { IBannerHomeRepository } from '../../../data/repositories/banner-home/banner-home-repository';
import { IBannerTopRepository } from '../../../data/repositories/banner-top/banner-top-repository';
import { IGetMediasService } from '../../../data/services/admin-medias/get-medias/get-medias-service';
import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { ReloadCookiesUseCase } from '../../reload-cookies/reload-cookies/reload-cookies-use-case';
import { MediasMapper } from './mapper/medias-mapper';

export type GetMediasRequest = void;
export type GetMediasResponse = void;

export class GetMediasUseCase {
  constructor(
    private logService: ILogService,
    private bannerTopRepository: IBannerTopRepository,
    private bannerHomeRepository: IBannerHomeRepository,
    private bannerBaseboardRepository: IBannerBaseboardRepository,
    private getMediasService: IGetMediasService,
    private reloadCookiesUseCase: ReloadCookiesUseCase,
  ) {}

  async handle(request: GetMediasRequest): Promise<GetMediasResponse> {
    try {
      await this.logService.info('GetMediasUseCase -> start');
      await this.logService.debug('GetMediasUseCase -> request', request);
      await this.reloadCookiesUseCase.handle();
      const response = await this.getMediasService.getMedias();
      if (response) {
        const medias = MediasMapper.toMediasModel(response);

        const bannerTop = medias.filter(
          (item) => item.spaceId === '01GV9SRT3ZGJPM7QHT0C3B06M4',
        );
        const bannerHome = medias.filter(
          (item) => item.spaceId === '01GV9SNB3EMH5GM1KMETPG1Q94',
        );
        const bannerBaseboard = medias.filter(
          (item) => item.spaceId === '01GV9SQG4NZQWFCB76YAAQMB2V',
        );

        await this.bannerTopRepository.deleteAll();
        await this.bannerHomeRepository.deleteAll();
        await this.bannerBaseboardRepository.deleteAll();

        await this.bannerTopRepository.saveList(bannerTop);
        await this.bannerHomeRepository.saveList(bannerHome);
        await this.bannerBaseboardRepository.saveList(bannerBaseboard);
      }
    } catch (error) {
      throw CustomError.handleError('GetMediasUseCase', error);
    } finally {
      await this.logService.info('GetMediasUseCase -> end');
    }
  }
}
