/* eslint-disable @typescript-eslint/no-explicit-any */

import { LogLevel } from '../../../../../models/common/log-level/log-level';
import { LogType } from '../../../../../models/log/log-type/log-type';
import { ILogStorageService } from '../../../log/log-storage/log-storage-service';
import { ILogService } from '../log-service';

export class LogServiceImpl implements ILogService {
  private logLevel: LogLevel;

  constructor(private logStorageService: ILogStorageService) {}

  async info(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.INFO) {
      console.info(message, data);
    }
  }

  async error(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.ERROR) {
      console.error(message, data);

      this.logStorageService.saveToOSInterface({
        id: '',
        date: new Date(),
        data,
        message,
        type: LogType.ERROR,
      });
    }
  }

  async warn(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.WARN) {
      console.warn(message, data);

      this.logStorageService.saveToOSInterface({
        id: '',
        date: new Date(),
        data,
        message,
        type: LogType.WARN,
      });
    }
  }

  async debug(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.DEBUG) {
      console.debug(message, data);

      this.logStorageService.saveToOSInterface({
        id: '',
        date: new Date(),
        data,
        message,
        type: LogType.DEBUG,
      });
    }
  }

  async setLogLevel(logLevel: LogLevel): Promise<void> {
    this.logLevel = logLevel;
  }
}
