import { makeIndexedDB } from '../core/indexed-db-factory';
import { IBannerBaseboardRepository } from './banner-baseboard-repository';
import { BannerBaseboardRepositoryImpl } from './banner-baseboard-repository-impl';

let bannerBaseboardRepository: IBannerBaseboardRepository;

export function makeBannerBaseboardRepository() {
  if (!bannerBaseboardRepository) {
    bannerBaseboardRepository = new BannerBaseboardRepositoryImpl(
      makeIndexedDB(),
    );
  }
  return bannerBaseboardRepository;
}
