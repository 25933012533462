import { DynamicComponent } from '../../../app/helpers/dynamic-component';
import { RepositoryKey } from '../../../models/common/repository/repository-key';
import { IEventService } from '../common/event/event-service';
import { TimeoutComponent } from './../../../app/components/common/timeout/timeout.component';
import { IKeyValueRepository } from './../../repositories/key-value/key-value-repository';
import { ILogService } from './../common/log/log-service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventTopic } from '../../../models/common/event-topic/event-topic';
import { IIdleService } from './idle-service';

export class IdleServiceImpl implements IIdleService {
  constructor(
    private logService: ILogService,
    private keyValueRepository: IKeyValueRepository,
    private eventService: IEventService,
  ) {}

  private async resetInactivityTracking(): Promise<void> {
    window.clearTimeout(globalThis.inactivityTracking);
    globalThis.inactivityTracking = null;

    globalThis.inactivityTracking = window.setTimeout(async () => {
      const currentRoute = await this.keyValueRepository.get(
        RepositoryKey.CURRENT_ROUTE,
      );

      DynamicComponent.openComponent(TimeoutComponent, { currentRoute });
      this.stopInactivityTracking();
    }, 30000);
  }

  async startInactivityTracking(): Promise<void> {
    await this.logService.info(
      'IdleServiceImpl.startInactivityTracking -> starting inactivity tracking',
    );

    await this.resetInactivityTracking();

    this.eventService.subscribe(EventTopic.USER_ITERACTED_WITH_THE_APP, () => {
      this.resetInactivityTracking();
    });
  }

  async stopInactivityTracking(): Promise<void> {
    await this.logService.info(
      'IdleServiceImpl.startInactivityTracking -> stopping inactivity tracking',
    );

    window.clearTimeout(globalThis.inactivityTracking);
    globalThis.inactivityTracking = null;

    this.eventService.subscribe(
      EventTopic.USER_ITERACTED_WITH_THE_APP,
      null,
      true,
    );
  }
}
