import { LoaderServiceImpl } from '../../../../data/services/common/loader/loader-service-impl';
import { ILoaderService } from './loader-service';

let loaderService: ILoaderService;

export function makeLoaderService() {
  if (!loaderService) {
    loaderService = new LoaderServiceImpl();
  }
  return loaderService;
}
