/* eslint-disable @typescript-eslint/no-explicit-any */
import { MessageComponent } from '../../../../app/components/common/message/message.component';
import { DynamicComponent } from '../../../../app/helpers/dynamic-component';
import { ILogService } from '../../../../data/services/common/log/log-service';
import { IMessageService } from '../../../../data/services/common/message/message-service';
import { CustomError } from '../../../../helpers/error/custom-error';
import { Action } from '../../../../models/common/action/action';

export class MessageServiceImpl implements IMessageService {
  constructor(private logService: ILogService) {}

  async success(message: string, closeIn?: number): Promise<Action> {
    return this.open(message, 'success', closeIn);
  }

  async error(message: string, closeIn?: number): Promise<Action> {
    return this.open(message, 'error', closeIn);
  }

  async ask(message: string, actions: Action[]): Promise<Action> {
    return this.open(message, 'error', 0, actions);
  }

  async customError(error: any, defaultMessage: string, closeIn?: number) {
    if (closeIn === 0) {
      return;
    }
    if (error instanceof CustomError) {
      this.error(error.getPublicError().errors.join('\n'), closeIn);
    } else {
      this.error(defaultMessage, closeIn);
    }
    await this.logService.error(error, closeIn);
  }

  private open(
    message: string,
    type: string,
    closeIn?: number,
    actions?: Action[],
  ): Promise<Action> {
    return new Promise((resolve) => {
      const params = { type, message, closeIn, actions };
      const dynamicRef = DynamicComponent.openComponent(
        MessageComponent,
        params,
      );
      dynamicRef.onClose((result) => resolve(result));
    });
  }
}
