/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
} from '@angular/core';
import { makeIdleService } from '../../../../data/services/idle/idle-service-factory';
import { makeRouterService } from '../../../../data/services/view/router/router-service-factory';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { Action, ActionType } from '../../../../models/common/action/action';
import { DynamicComponent } from '../../../helpers/dynamic-component';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';
@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutComponent
  extends SuperDynamicComponent
  implements DynamicComponent, AfterContentInit
{
  public idleService = makeIdleService();
  public routerService = makeRouterService();
  public message = LanguageKey.MESSAGE_TIMEOUT_SCREEN;
  public actions: Action[] = [
    { desc: LanguageKey.I_AM_HERE, type: ActionType.SUCCESS },
    { desc: LanguageKey.GO_HOME, type: ActionType.BASIC },
  ];
  public timeoutRef: ReturnType<typeof setTimeout>;
  public percent = 100;
  public seconds = '30';
  public intervalRef: ReturnType<typeof setInterval>;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngAfterContentInit(): void {
    this.timeoutRef = setTimeout(async () => await this.timeout(), 30000);

    let milleseconds = 30000;
    const timeUpdateView = 100;

    this.intervalRef = setInterval(async () => {
      milleseconds -= timeUpdateView;

      if (milleseconds <= 0) {
        return await this.timeout();
      }

      this.percent = (milleseconds * 100) / 30000;
      this.seconds = (milleseconds / 1000).toFixed(0);

      this.detectChanges();
    }, timeUpdateView);

    this.detectChanges();
  }

  clearTimeout() {
    clearTimeout(this.timeoutRef);
    clearInterval(this.intervalRef);

    this.timeoutRef = null;
    this.intervalRef = null;

    this.detectChanges();
  }

  async continue(): Promise<void> {
    await this.idleService.startInactivityTracking();
    this.clearTimeout();
    this.close();
  }

  async timeout(): Promise<void> {
    await this.logService.info(
      'TimeoutComponent.timeout -> Application timeout',
    );
    await this.idleService.stopInactivityTracking();
    this.clearTimeout();
    this.close();
    await this.routerService.navigate('/');
  }

  async onClickAction(action: Action): Promise<void> {
    if (action.type === ActionType.SUCCESS) {
      await this.continue();
    } else {
      await this.timeout();
    }
  }
}
