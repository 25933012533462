export const IndexedDBStores = {
  'key-value': '',
  'device-configs': '',
  'to-do': 'id,done',
  'banner-home': 'id',
  'banner-top': 'id',
  'banner-baseboard': 'id',
  log: 'id, date',
  //dontRemove
} as const;
