import { makeLogService } from '../log/log/log-service-factory';
import { IMessageService } from './message-service';
import { MessageServiceImpl } from './message-service-impl';

let messageService: IMessageService;

export function makeMessageService() {
  if (!messageService) {
    messageService = new MessageServiceImpl(makeLogService());
  }
  return messageService;
}
