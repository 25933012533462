<h1>{{ languageKey.TITLE_TIMEOUT_SCREEN | translateKey }}</h1>

<h2>{{ message | translateKey }}</h2>

<div class="progress">
  <app-spinner-progress
    [percent]="percent"
    [seconds]="seconds"
  ></app-spinner-progress>
</div>

<app-actions
  [actions]="actions"
  (clickAction)="onClickAction($event)"
></app-actions>
