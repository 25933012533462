import { makeKeyValueRepository } from '../../../data/repositories/key-value/key-value-repository-factory';
import { makeCookieService } from '../../../data/services/common/cookie/cookie-service-factory';
import { makeJwtService } from '../../../data/services/common/jwt/jwt-service-factory';
import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeReloadCookiesService } from '../../../data/services/reload-cookies/reload-cookies/reload-cookies-service-factory';
import { makeEnvironment } from '../../../environments/make-environment';
import { ReloadCookiesUseCase } from './reload-cookies-use-case';

export function makeReloadCookiesUseCase() {
  return new ReloadCookiesUseCase(
    makeLogService(),
    makeCookieService(),
    makeKeyValueRepository(),
    makeEnvironment(),
    makeJwtService(),
    makeReloadCookiesService(),
  );
}
