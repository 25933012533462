import { Log } from '../../../../models/log/log/log';
import { IIndexedDB } from '../../core/indexed-db';
import { DynamicRepositoryImpl } from '../../dynamic/dynamic-repository-impl';
import { ILogRepository } from './log-repository';

export class LogRepositoryImpl
  extends DynamicRepositoryImpl<Log>
  implements ILogRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'log');
  }
}
