import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  ViewContainerRef,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { filter, map, Observable, of } from 'rxjs';
import { makeKeyValueRepository } from '../data/repositories/key-value/key-value-repository-factory';

import { makeEventService } from '../data/services/common/event/event-service-factory';
import { makeLogService } from '../data/services/common/log/log/log-service-factory';
import { makeIdleService } from '../data/services/idle/idle-service-factory';
import { EventTopic } from '../models/common/event-topic/event-topic';
import { RepositoryKey } from '../models/common/repository/repository-key';
import { makeGetMediasUseCase } from '../usecases/get-medias/get-medias/get-medias-use-case-factory';
import { addEventListenerClicksOnScreen } from './helpers/clicks-on-screen';
import { CustomInjector } from './helpers/custom-injector';
import { DynamicComponent } from './helpers/dynamic-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public loading: Observable<boolean> = of(false);
  public interval: ReturnType<typeof setInterval> | undefined;
  public getMediasUseCase = makeGetMediasUseCase();
  public idleService = makeIdleService();
  public eventService = makeEventService();
  public keyValueRepository = makeKeyValueRepository();
  public logService = makeLogService();

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
  ) {
    DynamicComponent.setViewContainerRef(this.viewContainerRef);
    CustomInjector.setup(this.injector);
    this.startCrom();
    addEventListenerClicksOnScreen();

    this.loading = this.router.events.pipe(
      filter(
        (e: RouterEvent) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError,
      ),
      map((e) => {
        if (e instanceof NavigationStart) {
          (async () => {
            await this.keyValueRepository.set(
              RepositoryKey.CURRENT_ROUTE,
              e.url,
            );

            await this.logService.info(
              'AppComponent -> currentRoute -> ',
              e.url,
            );
          })();
        }

        return e instanceof NavigationStart;
      }),
    );
  }

  @HostListener('window:mousedown')
  @HostListener('window:keydown')
  monitorUserActivity() {
    this.eventService.emit(EventTopic.USER_ITERACTED_WITH_THE_APP);
  }

  private startCrom(): void {
    this.interval = setInterval(async () => {
      console.log('AppComponent => startCrom');
      await this.getMediasUseCase.handle();
    }, 900000);
  }
}
