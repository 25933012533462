/* eslint-disable @typescript-eslint/no-explicit-any */
import Dexie from 'dexie';
import { Id } from '../../../models/common/id/id';
import { makeIdService } from '../../services/common/id/id-service-factory';
import { IIndexedDB } from '../core/indexed-db';
import { IDynamicRepository } from './dynamic-repository';

export class DynamicRepositoryImpl<T extends Id>
  implements IDynamicRepository<T>
{
  protected dexie: Dexie;
  protected table: Dexie.Table;

  private idService = makeIdService();

  constructor(private indexedDB: IIndexedDB, private tableName: string) {
    try {
      this.dexie = this.indexedDB.getDB();
      this.table = this.dexie.table(tableName);
      this.dexie.open();
    } catch (error) {
      console.error(error);
    }
  }

  async getByIds(ids: string[]): Promise<T[]> {
    return await this.table.where('id').anyOf(ids).toArray();
  }

  async getById(id: string): Promise<T> {
    return await this.table.get(id);
  }

  async getAll(): Promise<T[]> {
    return await this.table.toArray();
  }

  async deleteById(id: string): Promise<void> {
    await this.table.delete(id);
  }

  async deleteAll(): Promise<void> {
    await this.table.clear();
  }

  async save(item: T): Promise<string> {
    if (!item.id) {
      item.id = this.idService.getULID();
    }

    await this.table.put(item);

    return item.id;
  }

  async saveList(items: T[]): Promise<string[]> {
    items.forEach((item) => {
      if (!item.id) {
        item.id = this.idService.getULID();
      }
    });

    await this.table.bulkPut(items);

    return items.map((item) => item.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getBetween(index: string, lower: any, upper: any): Promise<T[]> {
    return this.table.where(index).between(lower, upper).toArray();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteBellow(index: string, key: any): Promise<void> {
    await this.table.where(index).below(key).delete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteBetween(index: string, lower: any, upper: any): Promise<void> {
    this.table.where(index).between(lower, upper).delete();
  }
}
