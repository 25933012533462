import { IIndexedDB } from '../core/indexed-db';
import { DynamicRepositoryImpl } from '../dynamic/dynamic-repository-impl';
import { Medias } from './../../../models/media/media/medias';
import { IBannerHomeRepository } from './banner-home-repository';

export class BannerHomeRepositoryImpl
  extends DynamicRepositoryImpl<Medias>
  implements IBannerHomeRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'banner-home');
  }
}
