/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { SuperComponent } from '../../../helpers/super-component';

@Component({
  selector: 'app-spinner-progress',
  templateUrl: './spinner-progress.component.html',
  styleUrls: ['./spinner-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerProgressComponent
  extends SuperComponent
  implements AfterViewInit
{
  public _percent = 0;
  public _seconds = '30';
  public radius = 161; // (336 / 2) - (14 / 2) = 161
  public circumference = 0;

  @Input() set percent(value: number) {
    this._percent = value;
    this.detectChanges();
    this.progress();
  }
  @Input() set seconds(value: string) {
    this._seconds = value;
    this.detectChanges();
  }

  ngAfterViewInit(): void {
    this.circumference = 2 * Math.PI * this.radius;
    this.detectChanges();
    this.getElementProgressValue().style.strokeDasharray = this.circumference;
    this.progress();
  }

  progress() {
    const progress = this._percent / 100;
    const strokeDashoffset = this.circumference * (1 - progress);
    this.detectChanges();

    this.getElementProgressValue().style.strokeDashoffset = strokeDashoffset;
  }

  getElementProgressValue(): any {
    return document.querySelector('.progress__value');
  }
}
