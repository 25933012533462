import {
  addDays,
  addHours,
  format,
  isValid,
  parse,
  subDays,
  subHours,
} from 'date-fns';
import { IDateService } from '../../../../data/services/date/date/date-service';

export class DateServiceImpl implements IDateService {
  currentDateMinusDays(days: number): Date {
    return subDays(new Date(), days);
  }

  currentDatePlusDays(days: number): Date {
    return addDays(new Date(), days);
  }

  currentDateMinusHours(hours: number): Date {
    return subHours(new Date(), hours);
  }

  currentDatePlusHours(hours: number): Date {
    return addHours(new Date(), hours);
  }

  dateFromString(stringDate: string, pattern: string): Date {
    return parse(stringDate, pattern, new Date());
  }

  format(date: Date, pattern: string): string {
    return format(date, pattern);
  }

  isValid(date: string | Date, pattern: string): boolean {
    if (typeof date === 'string') {
      return isValid(parse(date, pattern, new Date()));
    }

    return isValid(format(date, pattern));
  }
}
