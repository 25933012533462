import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { makeKeyValueRepository } from '../../data/repositories/key-value/key-value-repository-factory';
import { makeCookieService } from '../../data/services/common/cookie/cookie-service-factory';
import { makeJwtService } from '../../data/services/common/jwt/jwt-service-factory';
import { makeRouterService } from '../../data/services/view/router/router-service-factory';
import { CookieKey } from '../../models/common/cookie-key/cookie-key';
import { makeReloadCookiesUseCase } from '../../usecases/reload-cookies/reload-cookies/reload-cookies-use-case-factory';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public routerService = makeRouterService();
  public keyValueRepository = makeKeyValueRepository();
  public cookieService = makeCookieService();
  public jwtService = makeJwtService();
  public reloadCookies = makeReloadCookiesUseCase();

  async canActivate(): Promise<boolean> {
    const accessToken = this.cookieService.get(CookieKey.DEVICE_ACCESS_TOKEN);
    if (!accessToken) {
      const response = await this.reloadCookies.handle();
      if (response) {
        return true;
      }
      this.routerService.navigate('device/auth');
      return false;
    }

    return true;
  }
}
