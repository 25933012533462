import { CustomError } from '../../../../helpers/error/custom-error';
import { AppEnvironment } from '../../../../models/common/environment/environment';
import { ILogService } from '../../common/log/log-service';
import { HttpFetch } from './../../../../helpers/http-fetch';
import { IReloadCookiesService } from './reload-cookies-service';

export class ReloadCookiesServiceImpl implements IReloadCookiesService {
  constructor(
    private logService: ILogService,
    private environment: AppEnvironment,
  ) {}

  async reloadCookies(refreshToken: string): Promise<string> {
    try {
      await this.logService.info(
        'DeviceAuthServiceImpl.getAccessToken -> start',
      );

      const result = await HttpFetch.exec(this.environment.adminWebServiceURL)
        .path('/device/auth/access-token')
        .headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`,
        })
        .checkStatus(200)
        .fetch();

      return result.accessToken;
    } catch (error) {
      throw CustomError.handleError(
        'DeviceAuthServiceImpl.getAccessToken -> error',
        error,
      );
    } finally {
      await this.logService.info('DeviceAuthServiceImpl.getAccessToken -> end');
    }
  }
}
