import { Medias } from '../../../models/media/media/medias';
import { IIndexedDB } from '../core/indexed-db';
import { DynamicRepositoryImpl } from '../dynamic/dynamic-repository-impl';
import { IBannerBaseboardRepository } from './banner-baseboard-repository';

export class BannerBaseboardRepositoryImpl
  extends DynamicRepositoryImpl<Medias>
  implements IBannerBaseboardRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'banner-baseboard');
  }
}
