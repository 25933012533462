import { makeEnvironment } from '../../../environments/make-environment';
import { makeVolatileLogService } from '../../services/common/log/volatile-log/volatile-log-service-factory';
import { makeFlashStorageRepository } from '../flash-storage/flash-storage-repository-factory';
import { IIndexedDB } from './indexed-db';
import { IndexedDBImpl } from './Indexeddb-impl';

let indexedDB: IIndexedDB;

export function makeIndexedDB() {
  if (!indexedDB) {
    indexedDB = new IndexedDBImpl(
      makeEnvironment(),
      makeFlashStorageRepository(),
      makeVolatileLogService(),
    );
  }
  return indexedDB;
}
