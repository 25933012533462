export enum EnvironmentName {
  PROD = 'prod',
  DEV = 'dev',
  TEST = 'test',
}

export class AppEnvironment {
  public ENV_NAME: EnvironmentName;
  public PROJECT_NAME: string;
  public PROJECT_VERSION: string;
  public PROJECT_BUILD_DATE: number;
  public OS_INTERFACE_URL: string;
  public PRINTER_URL: string;
  public AUTOPAG_URL: string;
  public SURVEY_URL: string;
  public SURVEY_QUESTION_ID: string;
  public SCANER_URL: string;
  public DEVICE_CONFIG: string;
  public MIDIAS_URL: string;

  get isTest(): boolean {
    return this.ENV_NAME === EnvironmentName.TEST;
  }

  get isDev(): boolean {
    return this.ENV_NAME === EnvironmentName.DEV;
  }

  get isProd(): boolean {
    return this.ENV_NAME === EnvironmentName.PROD;
  }

  get buildDate(): string {
    return new Date(this.PROJECT_BUILD_DATE).toLocaleString();
  }

  get fullVersion() {
    return `Versão: ${this.PROJECT_VERSION} - Build: ${this.buildDate}`;
  }

  get adminWebServiceURL(): string {
    const homolog = window.location.host.indexOf('homolog') > -1;
    const dev = window.location.host.indexOf('localhost') > -1;
    if (homolog || dev) {
      return 'https://api.central.homolog.vsd.app/v1';
    } else {
      return 'https://api.central.vsd.app/v1';
    }
  }

  get getDomain(): string {
    const homolog = window.location.host.indexOf('homolog') > -1;
    const dev =
      window.location.host.indexOf('localhost') > -1 ||
      window.location.port === '4200';

    if (homolog) {
      return '.homolog.vsd.app';
    } else if (dev) {
      return 'localhost';
    } else {
      return '.vsd.app';
    }
  }
}
