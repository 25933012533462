export enum LanguageKey {
  AT_HOME = 'AT_HOME',
  CANCEL = 'CANCEL',
  CONTINUE = 'CONTINUE',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CONFIRM = 'CONFIRM',
  DESCRIPTION = 'DESCRIPTION',
  DONE = 'DONE',
  DISCOVER_THE_NEWS = 'DISCOVER_THE_NEWS',
  EDIT = 'EDIT',
  ENGLISH = 'ENGLISH',
  DO_YOU_WANT_TO_ABANDON_THIS_ORDER_AND_START_SHOPPING_AGAIN = 'DO_YOU_WANT_TO_ABANDON_THIS_ORDER_AND_START_SHOPPING_AGAIN',
  GO_HOME = 'GO_HOME',
  HELLO = 'HELLO',
  I_AM_HERE = 'I_AM_HERE',
  MESSAGE_TIMEOUT_SCREEN = 'MESSAGE_TIMEOUT_SCREEN',
  MESSAGE_TIMEOUT_SCREEN_WHEN_RECEIPT_METHOD_OPEN = 'MESSAGE_TIMEOUT_SCREEN_WHEN_RECEIPT_METHOD_OPEN',
  ON_HERE = 'ON_HERE',
  OR_IF_YOU_PREFER = 'OR_IF_YOU_PREFER',
  PORTUGUESE = 'PORTUGUESE',
  RESTART = 'RESTART',
  SAVE = 'SAVE',
  SEE_THE_FULL_MENU = 'SEE_THE_FULL_MENU',
  SPANISH = 'SPANISH',
  TITLE_TIMEOUT_SCREEN = 'TITLE_TIMEOUT_SCREEN',
  TRY_OUR_NEW_FLAVORS = 'TRY_OUR_NEW_FLAVORS',
  WELCOME = 'WELCOME',
  WHERE_ARE_YOU_GOING_TO_EAT = 'WHERE_ARE_YOU_GOING_TO_EAT',
  YES_I_AM_HERE = 'YES_I_AM_HERE',
  END_SURVEY_AND_GO_BACK_TO_THE_BEGINNING = 'END_SURVEY_AND_GO_BACK_TO_THE_BEGINNING',
}
