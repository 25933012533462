/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { EventTopic } from '../../../../models/common/event-topic/event-topic';
import { IEventService } from './event-service';

export class EventServiceImpl implements IEventService {
  topics: Map<EventTopic, EventEmitter<any>> = new Map();

  emit(topic: EventTopic, value?: any): void {
    const eventEmitter = this.getEventEmitter(topic);
    eventEmitter.emit(value);
  }

  subscribe(
    topic: EventTopic,
    handle: (value: any) => void,
    unsubscriberAll = false,
  ): Subscription {
    const eventEmitter = this.getEventEmitter(topic, unsubscriberAll);
    const subscription = eventEmitter.subscribe(handle);
    return subscription;
  }

  createEventEmitterTopic(topic: EventTopic) {
    this.topics.set(topic, new EventEmitter<any>());
  }

  getEventEmitter(
    topic: EventTopic,
    unsubscriberAll = false,
  ): EventEmitter<any> {
    if (unsubscriberAll) {
      this.unsubscriberAll(topic);
    }
    if (!this.topics.has(topic)) {
      this.createEventEmitterTopic(topic);
    }
    return this.topics.get(topic);
  }

  unsubscriberAll(topic: EventTopic) {
    if (!this.topics[topic]) {
      this.createEventEmitterTopic(topic);
    }
    for (const observer of this.topics[topic]?.observers || []) {
      observer.unsubscribe();
    }
  }
}
