import { ChangeDetectionStrategy, Component } from '@angular/core';
import { makeLoaderService } from '../../../data/services/common/loader/loader-service-factory';
import { makeMessageService } from '../../../data/services/common/message/message-service-factory';
import { makeTimeoutService } from '../../../data/services/common/timeout/timeout-service-factory';
import { LanguageKey } from '../../../main/translate/language-key/language-key';
import { Action, ActionType } from '../../../models/common/action/action';

type Question = {
  title: string;
  type: string;
  atribute: string;
  response: string;
};

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentsComponent {
  actions: Action[] = [
    { desc: LanguageKey.EDIT, type: ActionType.BASIC },
    { desc: LanguageKey.CONFIRM, type: ActionType.SUCCESS },
  ];

  messageService = makeMessageService();
  loaderService = makeLoaderService();

  questionIndex = 0;
  questions: Question[] = [];

  constructor() {
    this.resetQuestions();
  }
  async showLoader() {
    const { close, updateMessage } = this.loaderService.open('Batatinhaaa');
    setTimeout(() => {
      updateMessage('New Batinhaaaa Frita');
    }, 2000);
    setTimeout(() => {
      close();
    }, 4000);
  }
  resetQuestions() {
    this.questionIndex = 0;
    this.questions = [
      {
        title: 'Informe o código de ativação do TOTEM',
        type: 'numeric',
        atribute: 'auth_code',
        response: '',
      },
      {
        title: 'Informe o serial do TOTEM',
        type: 'alphanumeric',
        atribute: 'serial_number',
        response: '',
      },
    ];
  }

  async onAction(action: Action) {
    if (action.desc === LanguageKey.CONFIRM) {
      if (!this.questions[this.questionIndex].response) {
        return this.messageService.error(
          this.questions[this.questionIndex].title,
        );
      }
      if (this.questionIndex + 1 < this.questions.length) {
        this.questionIndex++;
      } else {
        console.log('FINISH');
      }
    } else if (action.desc === LanguageKey.EDIT) {
      return this.messageService.error('Senha incorreta');
    }
    return null;
  }

  showTimeout() {
    console.log('Start timeout');
    makeTimeoutService();
  }
}
