<!-- <p>components works!</p>
<div class="questions">
  <h2>{{ questions[questionIndex].title }}</h2>
  <app-input
    [type]="questions[questionIndex].type"
    [(ngModel)]="questions[questionIndex].response"
    [actions]="actions"
    (clickAction)="onAction($event)"
  >
  </app-input>
</div> -->
<button (click)="showTimeout()">Show Screen Timeout</button>
