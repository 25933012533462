import { IJwtService } from './../../common/jwt/jwt-service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpFetch } from '../../../../helpers/http-fetch';
import { RepositoryKey } from '../../../../models/common/repository/repository-key';
import { IKeyValueRepository } from '../../../repositories/key-value/key-value-repository';
import { ILogService } from '../../common/log/log-service';
import { CustomError } from './../../../../helpers/error/custom-error';
import { AppEnvironment } from './../../../../models/common/environment/environment';
import { IGetMediasService } from './get-medias-service';

export class GetMediasServiceImpl implements IGetMediasService {
  constructor(
    private logService: ILogService,
    private environment: AppEnvironment,
    private keyValueRepository: IKeyValueRepository,
    private jwtService: IJwtService,
  ) {}

  async getMedias(): Promise<any> {
    this.logService.debug('DeviceAuthServiceImpl -> request');
    const token = await this.keyValueRepository.get(RepositoryKey.AUTH_TOKEN);
    const tokenDecode = this.jwtService.getDataFromToken(token);
    try {
      const response = await HttpFetch.exec(
        `${this.environment.MIDIAS_URL}/v1/entity/${tokenDecode.entityId}/software/01GV9SBABYFR7CY4YVTQVR3HRS/media/schedule`,
      )
        .method('GET')
        .useAuthorization()
        .disabledCredentials()
        .returnTypeJSON()
        .checkStatus(200)
        .fetch();

      return response.data;
    } catch (error) {
      throw CustomError.handleError('DeviceAuthServiceImpl -> error', error);
    } finally {
      this.logService.debug('DeviceAuthServiceImpl -> end');
    }
  }
}
