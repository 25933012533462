import { makeLogService } from '../log/log/log-service-factory';
import { ITimeoutService } from './timeout-service';
import { TimeoutServiceImpl } from './timeout-service-impl';

let timeoutService: ITimeoutService;

export function makeTimeoutService() {
  if (!timeoutService) {
    timeoutService = new TimeoutServiceImpl(makeLogService());
  }
  return timeoutService;
}
