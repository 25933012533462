/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, Type, ViewContainerRef } from '@angular/core';

export interface IDynamicComponent {
  componentRef: ComponentRef<IDynamicComponent>;
  params?: any;
}

export interface DynamicComponentResponse {
  componentRef: ComponentRef<IDynamicComponent>;
  onClose: (callback: (result?: any) => void) => void;
}

export class DynamicComponent {
  private static viewContainerRef: ViewContainerRef;
  private static componentsRef = new Map<
    ComponentRef<IDynamicComponent>,
    (result?: any) => void
  >();

  static setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  static openComponent(
    componentType: Type<IDynamicComponent>,
    params?: any,
    clearBeforeCreate = false,
  ): DynamicComponentResponse {
    if (clearBeforeCreate) {
      this.viewContainerRef.clear();
    }

    const componentRef = this.viewContainerRef.createComponent(componentType);

    componentRef.instance.params = params;
    componentRef.instance.componentRef = componentRef;
    this.componentsRef.set(componentRef, null);

    return {
      componentRef,
      onClose: (callback: (result?) => void) => {
        this.componentsRef.set(componentRef, callback);
      },
    };
  }

  static close(componentRef: ComponentRef<any>, result?: any) {
    componentRef?.destroy();
    const callback = this.componentsRef.get(componentRef);
    if (callback) {
      callback(result);
    }
    this.componentsRef.delete(componentRef);
  }

  static closeAllComponents() {
    Array.from(this.componentsRef.keys()).forEach((component) => {
      this.close(component);
    });
  }
}
