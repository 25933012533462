/* eslint-disable @typescript-eslint/no-explicit-any */
export const timeoutPromise = (
  ms = 30000,
  promise: Promise<any>,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(`Não foi possível executar a promise em ${ms}ms`));
    }, ms);
    promise.then(resolve, reject);
  });
};
