import { HttpStatusCode } from '@angular/common/http';
import { ILogService } from '../data/services/common/log/log-service';
import { CookieKey } from './../models/common/cookie-key/cookie-key';
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { makeCookieService } from '../data/services/common/cookie/cookie-service-factory';
import { makeRouterService } from '../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../environments/make-environment';
import { CustomError, CustomErrorType } from './error/custom-error';
import { timeoutPromise } from './promise/timeout-promise';

export class HttpFetch {
  private useCredentials = true;
  private useUrl: string;
  private useMethod = 'GET';
  private useHeaders: any = { 'Content-Type': 'application/json' };
  private useLocalWS = false;
  private useTimeoutMS = 0;
  private useCheckStatus = 0;
  private useResponseType = 'json';
  private useBody: any;

  environment = makeEnvironment();
  cookieService = makeCookieService();
  routerService = makeRouterService();

  constructor(public url: string, private logService?: ILogService) {
    this.useUrl = url;
  }

  static exec(url: string) {
    return new HttpFetch(url);
  }

  useAuthorization() {
    const accessToken = this.cookieService.get(CookieKey.DEVICE_ACCESS_TOKEN);

    if (!accessToken) {
      throw new CustomError(CustomErrorType.UNAUTHENTICATED);
    }

    // if (this.environment.getDomain !== 'localhost') return this;

    this.useHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    return this;
  }

  useWS() {
    this.useLocalWS = true;
    return this;
  }

  queryParams(params: any) {
    this.useUrl += this.serialize(params);
    return this;
  }

  path(path: string) {
    this.useUrl += path;
    return this;
  }

  timeout(ms: number) {
    this.useTimeoutMS = ms;
    return this;
  }

  method(method: string) {
    this.useMethod = method.toUpperCase();
    return this;
  }

  headers(headers: any) {
    this.useHeaders = headers;
    return this;
  }

  checkStatus(status: number) {
    this.useCheckStatus = status;
    return this;
  }

  body(body: any) {
    this.useBody = body;
    return this;
  }

  returnTypeJSON() {
    this.useResponseType = 'json';
    return this;
  }

  returnTypeText() {
    this.useResponseType = 'text';
    return this;
  }

  returnTypeJSONXML() {
    this.useResponseType = 'jsonxml';
    return this;
  }

  async fetch(): Promise<any> {
    let response: Response;
    try {
      let promise: Promise<Response>;
      if (!this.useLocalWS || (await !this.isAvailableWS())) {
        promise = this.fetchDirect();
      } else {
        promise = this.fetchUseWS();
      }

      response = await timeoutPromise(this.useTimeoutMS || 10000, promise);

      if (this.useCheckStatus) {
        if (response.status !== this.useCheckStatus) {
          let error: any;

          try {
            error = await response.json();
          } catch (error) {
            await this.logService.warn('HttpFetch.fetch', error);
          }

          throw CustomError.generic(
            [
              'HttpFetch Error',
              `Expected status ${this.useCheckStatus}`,
              `Received status: ${response?.status}`,
              error,
            ],
            response?.status,
          );
        }
      }

      switch (this.useResponseType) {
        case 'json':
          return response.json();
        case 'jsonxml':
          return response.json();
        case 'text':
          return response.text();
      }
    } catch (error) {
      if (
        error?.status === HttpStatusCode.Unauthorized ||
        error?.status === HttpStatusCode.Forbidden
      ) {
        throw error;
      }

      if (error instanceof CustomError) {
        throw error;
      }

      throw CustomError.generic(
        [
          'HttpFetch Error',
          { error, fetchData: this.useBody },
          `Received status: ${response?.status}`,
        ],
        response?.status,
      );
    }
  }

  public disabledCredentials() {
    this.useCredentials = false;
    return this;
  }

  private fetchDirect(): Promise<Response> {
    return fetch(this.useUrl, {
      method: this.useMethod,
      credentials: this.useCredentials ? 'include' : 'omit',
      headers: this.useHeaders,
      body: this.useBody,
    });
  }

  private fetchUseWS(): Promise<Response> {
    return fetch(this.environment.OS_INTERFACE_URL + '/fetch', {
      method: 'POST',
      credentials: this.useCredentials ? 'include' : 'omit',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        URL: this.useUrl,
        method: this.useMethod,
        headers: this.useHeaders,
        body: this.useBody,
        type: this.useResponseType === 'jsonxml' ? 'xml' : this.useResponseType,
      }),
    });
  }

  private serialize(queryParams: any) {
    const str = [];
    for (const p in queryParams) {
      if (queryParams.hasOwnProperty(p)) {
        if (`${queryParams[p]}`) {
          str.push(
            encodeURIComponent(p) + '=' + encodeURIComponent(queryParams[p]),
          );
        }
      }
    }
    return str.length ? '?' + str.join('&') : '';
  }

  async isAvailableWS(): Promise<boolean> {
    try {
      const result = await fetch(
        this.environment.OS_INTERFACE_URL + '/version-application',
      );
      return result?.status === 200;
    } catch (e) {
      return false;
    }
  }
}
