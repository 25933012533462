import { makeIndexedDB } from '../core/indexed-db-factory';
import { IBannerHomeRepository } from './banner-home-repository';
import { BannerHomeRepositoryImpl } from './banner-home-repository-impl';

let bannerHomeRepository: IBannerHomeRepository;

export function makeBannerHomeRepository() {
  if (!bannerHomeRepository) {
    bannerHomeRepository = new BannerHomeRepositoryImpl(makeIndexedDB());
  }
  return bannerHomeRepository;
}
