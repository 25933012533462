import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
} from '@angular/core';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { Action } from '../../../../models/common/action/action';
import {
  DynamicComponent,
  IDynamicComponent,
} from '../../../helpers/dynamic-component';

const TWO_AND_A_HALF_SECONDS = 2500;

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements IDynamicComponent {
  public componentRef: ComponentRef<IDynamicComponent>;

  public message: LanguageKey;
  public actions: Action[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  set params(params: {
    message: LanguageKey;
    actions: Action[];
    closeIn: number;
  }) {
    this.message = params?.message;
    this.actions = params?.actions || [];

    if (!this.actions.length) {
      this.closeIn(params?.closeIn);
    }

    this.changeDetectorRef.detectChanges();
  }

  close(action?: Action) {
    DynamicComponent.close(this.componentRef, action);
  }

  closeIn(closeIn: number = TWO_AND_A_HALF_SECONDS) {
    setTimeout(() => this.close(), closeIn);
  }
}
