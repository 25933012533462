import { LocationId } from '../location-id/location-id';

export type Language = {
  id: LocationId;
  name: string;
};

export const languages = new Map<LocationId, Language>([
  ['pt-BR', { id: 'pt-BR', name: 'Português (Brasil)' }],
  ['en-US', { id: 'en-US', name: 'Inglês (Estados Unidos)' }],
  ['es-ES', { id: 'es-ES', name: 'Espanhol (Espanha)' }],
]);
