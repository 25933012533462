import { makeEnvironment } from '../../../../environments/make-environment';
import { makeLogRepository } from '../../../repositories/log/log/log-repository-factory';
import { makeDateService } from '../../date/date/date-service-factory';
import { ILogStorageService } from './log-storage-service';
import { LogStorageServiceImpl } from './log-storage-service-impl';

export function makeLogStorageService(): ILogStorageService {
  return new LogStorageServiceImpl(
    makeDateService(),
    makeEnvironment(),
    makeLogRepository(),
  );
}
