/* eslint-disable @typescript-eslint/no-explicit-any */

import jwt_decode from 'jwt-decode';
import { IJwtService } from './jwt-service';

export class JwtServiceImpl implements IJwtService {
  getDataFromToken(data: string): any {
    return jwt_decode(data);
  }
}
