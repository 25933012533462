import { makeIndexedDB } from '../../core/indexed-db-factory';
import { ILogRepository } from './log-repository';
import { LogRepositoryImpl } from './log-repository-impl';

let logRepository: ILogRepository;

export function makeLogRepository() {
  if (!logRepository) {
    logRepository = new LogRepositoryImpl(makeIndexedDB());
  }

  return logRepository;
}
