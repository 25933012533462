import { Pipe, PipeTransform } from '@angular/core';
import { makeTranslateService } from '../../../data/services/common/translate/translate-service-factory';
import { LanguageKey } from '../../../main/translate/language-key/language-key';

@Pipe({
  name: 'translateKey',
  pure: false,
})
export class TranslateKeyPipe implements PipeTransform {
  translateService = makeTranslateService();

  transform(key: LanguageKey): string {
    return this.translateService.translateKey(key);
  }
}
