import { makeIndexedDB } from '../core/indexed-db-factory';
import { IBannerTopRepository } from './banner-top-repository';
import { BannerTopRepositoryImpl } from './banner-top-repository-impl';

let bannerTopRepository: IBannerTopRepository;

export function makeBannerTopRepository() {
  if (!bannerTopRepository) {
    bannerTopRepository = new BannerTopRepositoryImpl(makeIndexedDB());
  }
  return bannerTopRepository;
}
