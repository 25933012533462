import { ILogService } from '../log-service';
import { VolatileLogServiceImpl } from './volatile-log-service-impl';

let logService: ILogService;

export function makeVolatileLogService() {
  if (!logService) {
    logService = new VolatileLogServiceImpl();
  }

  return logService;
}
