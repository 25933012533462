/* eslint-disable @typescript-eslint/no-explicit-any */

import { LanguageKey } from '../../../main/translate/language-key/language-key';

export enum ActionType {
  SUCCESS = 'success',
  BASIC = 'basic',
  CANCEL = 'cancel',
  CONTINUE = 'continue',
  CONFIRM = 'confirm',
}

export type Action = {
  desc: LanguageKey;
  type: ActionType;
  extraInfo?: any;
};
