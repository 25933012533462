import { IEventService } from './event-service';
import { EventServiceImpl } from './event-service-impl';

let eventService: IEventService;

export function makeEventService() {
  if (!eventService) {
    eventService = new EventServiceImpl();
  }
  return eventService;
}
