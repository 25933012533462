import { TimeoutComponent } from '../../../../app/components/common/timeout/timeout.component';
import { DynamicComponent } from '../../../../app/helpers/dynamic-component';
import { ILogService } from '../../../../data/services/common/log/log-service';
import { ITimeoutService } from '../../../../data/services/common/timeout/timeout-service';

export class TimeoutServiceImpl implements ITimeoutService {
  // timeoutRef: NodeJS.Timeout;
  timeoutRef: ReturnType<typeof setTimeout>;

  constructor(private logService: ILogService) {
    document.addEventListener('click', () => this.clickToggle());
    globalThis.clickToggle = () => this.clickToggle();
  }
  clickToggle(): void {
    this.clearTimeout();
    this.timeoutRef = setTimeout(async () => {
      //TODO - adicionar validação de sessões da aplicação abaixo
      // if (
      //   (globalThis.paymentInProgress || !globalThis.totemInUse) &&
      //   !globalThis.isReceiptMethodsOpen
      // ) {
      //   this.clickToggle();
      //   return;
      // }
      this.logService.info('Open screen of timeout');
      DynamicComponent.openComponent(TimeoutComponent);
    }, 30000);
  }
  clearTimeout(): void {
    clearTimeout(this.timeoutRef);
  }
}
