import { makeBannerBaseboardRepository } from '../../../data/repositories/banner-baseboard/banner-baseboard-repository-factory';
import { makeBannerHomeRepository } from '../../../data/repositories/banner-home/banner-home-repository-factory';
import { makeBannerTopRepository } from '../../../data/repositories/banner-top/banner-top-repository-factory';
import { makeGetMediasService } from '../../../data/services/admin-medias/get-medias/get-medias-service-factory';
import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeReloadCookiesUseCase } from '../../reload-cookies/reload-cookies/reload-cookies-use-case-factory';
import { GetMediasUseCase } from './get-medias-use-case';

export function makeGetMediasUseCase() {
  return new GetMediasUseCase(
    makeLogService(),
    makeBannerTopRepository(),
    makeBannerHomeRepository(),
    makeBannerBaseboardRepository(),
    makeGetMediasService(),
    makeReloadCookiesUseCase(),
  );
}
