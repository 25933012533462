import { IFlashStorageRepository } from './flash-storage-repository';

export class FlashStorageRepositoryImpl implements IFlashStorageRepository {
  private localStorage: Storage;
  constructor() {
    this.localStorage = window.localStorage;
  }
  set(key: string, value: string): void {
    return this.localStorage.setItem(key, value);
  }
  get(key: string): string {
    return this.localStorage.getItem(key);
  }
  delete(key: string): void {
    return this.localStorage.removeItem(key);
  }
  deleteAllKeys(): void {
    return this.localStorage.clear();
  }
}
