import { Medias } from './../../../../models/media/media/medias';

export class MediasMapper {
  static toMediasModel(mediaAdmin): Medias[] {
    const media = new Medias();
    let mediaArray = Object.values(media);
    mediaArray = mediaAdmin.map((item) => ({
      id: item.id || null,
      status: item.status || null,
      deleted: item.deleted || false,
      createdAt: item.createdAt || null,
      updatedAt: item.updatedAt || null,
      name: item.name,
      filename: item.filename,
      type: item.type,
      duration: item.duration,
      entityId: item.entityId,
      spaceId: item.spaceId,
      schedules: item?.schedulePeriods?.map((schedule) => ({
        id: schedule.id || null,
        status: schedule.status || null,
        deleted: schedule.deleted || false,
        createdAt: schedule.createdAt || null,
        updatedAt: schedule.updatedAt || null,
        mediaId: schedule.mediaId,
        startDate: schedule.startDate,
        endDate: schedule.endDate,
        sunday: schedule.sunday,
        monday: schedule.monday,
        tuesday: schedule.tuesday,
        wednesday: schedule.wednesday,
        thursday: schedule.thursday,
        friday: schedule.friday,
        saturday: schedule.saturday,
        scheduleTimes: schedule?.times?.map((times) => ({
          id: times?.id,
          startTime: times?.startTime,
          endTime: times?.endTime,
        })),
      })),
      space: {
        id: item?.space?.id || null,
        status: item?.space?.status || null,
        deleted: item?.space?.deleted || false,
        createdAt: item?.space?.createdAt || null,
        updatedAt: item?.space?.updatedAt || null,
        software: {
          id: item?.space?.software?.id || null,
          status: item?.space?.software?.status || null,
          deleted: item?.space?.software?.deleted || false,
          createdAt: item?.space?.software?.createdAt || null,
          updatedAt: item?.space?.software?.updatedAt || null,
          name: item?.space?.software?.name,
        },
        deviceId: item?.space?.deviceId,
        entityId: item?.space?.entityId,
        name: item?.space?.name,
        softwareId: item?.space?.softwareId,
        orientation: item?.space?.orientation,
        minPxWidth: item?.space?.minPxWidth,
        minPxHeight: item?.space?.minPxHeight,
        maxPxWidth: item?.space?.maxPxWidth,
        maxPxHeight: item?.space?.maxPxHeight,
        aspectRatioWidth: item?.space?.aspectRatioWidth,
        aspectRatioHeight: item?.space?.aspectRatioHeight,
      },
    }));
    return mediaArray;
  }
}
