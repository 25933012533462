import { LoaderComponent } from '../../../../app/components/common/loader/loader.component';
import { DynamicComponent } from '../../../../app/helpers/dynamic-component';
import { ILoaderService } from '../../../../data/services/common/loader/loader-service';

export class LoaderServiceImpl implements ILoaderService {
  open(message?: string) {
    const dynamic = DynamicComponent.openComponent(LoaderComponent, message);
    return {
      close: () => {
        DynamicComponent.close(dynamic.componentRef);
      },
      updateMessage: (messageUpdate: string) => {
        dynamic.componentRef.instance.params = messageUpdate;
      },
    };
  }
}
