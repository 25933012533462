import { IJwtService } from './jwt-service';
import { JwtServiceImpl } from './jwt-service-impl';

let jwtService: IJwtService;
export function makeJwtService() {
  if (!jwtService) {
    jwtService = new JwtServiceImpl();
  }
  return jwtService;
}
