import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ComponentsComponent } from './pages/components/components.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./pages/menu/menu.module').then((m) => m.MenuModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'device',
    loadChildren: () =>
      import('./pages/device/device.module').then((m) => m.DeviceModule),
  },
  // {
  //   path: 'admin',
  //   loadChildren: () =>
  //     import('./pages/admin/admin.module').then((m) => m.AdminModule),
  // },
  {
    path: 'log',
    loadChildren: () =>
      import('./pages/log/log.module').then((m) => m.LogModule),
  },
  { path: 'components', component: ComponentsComponent },
  {
    path: 'to-do',
    loadChildren: () =>
      import('./pages/to-do/to-do.module').then((m) => m.ToDoModule),
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
