import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { makeEnvironment } from './environments/make-environment';
import { setup } from './main/setup';

(async () => {
  if (makeEnvironment().isProd) {
    enableProdMode();
  }

  try {
    await setup();
    await platformBrowserDynamic().bootstrapModule(AppModule);
  } catch (error) {
    console.error('Angular Main Setup Error', error);
  }
})();
