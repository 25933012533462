import Cookies from 'js-cookie';
import { CookieKey } from '../../../../models/common/cookie-key/cookie-key';
import { CookieOptions } from '../../../../models/common/cookie-option/cookie-option';
import { ICookieService } from './cookie-service';

export class CookieServiceImpl implements ICookieService {
  get(key: CookieKey) {
    return Cookies.get(key);
  }

  set(key: CookieKey, value: string, options: CookieOptions = {}) {
    Cookies.set(key, value, {
      expires: options.expires || 5000,
      domain: options.domain,
      path: options.path || '/',
    });
  }

  delete(key: CookieKey, options: CookieOptions = {}) {
    Cookies.remove(key, {
      path: options.path || '/',
      domain: options.domain,
    });
  }
}
