import { ILogRepository } from '../../../../data/repositories/log/log/log-repository';
import { IDateService } from '../../../../data/services/date/date/date-service';
import { ILogStorageService } from '../../../../data/services/log/log-storage/log-storage-service';
import { AppEnvironment } from '../../../../models/common/environment/environment';
import { Log } from '../../../../models/log/log/log';

const PERIOD = 1;

export class LogStorageServiceImpl implements ILogStorageService {
  constructor(
    private dateService: IDateService,
    private environment: AppEnvironment,
    private logRepository: ILogRepository,
  ) {}

  async saveToDexieDB(log: Log): Promise<void> {
    await this.logRepository.save(log);
  }

  async saveToOSInterface(log: Log): Promise<void> {
    const body = {
      software: this.environment.PROJECT_NAME,
      date: log?.date,
      type: log?.type,
      message: log?.message,
    };

    fetch(`${this.environment.OS_INTERFACE_URL}/log/insert`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    await this.saveToDexieDB(log);
  }

  async getBetweenDateFromDexieDB(
    startDate: Date,
    endDate: Date,
  ): Promise<Log[]> {
    return await this.logRepository.getBetween('date', startDate, endDate);
  }

  async deleteByPeriodFromOSInterface(): Promise<void> {
    const body = {
      software: this.environment.PROJECT_NAME,
      removeBeforeDate: this.dateService.currentDateMinusDays(PERIOD),
    };

    fetch(`${this.environment.OS_INTERFACE_URL}/log/clean`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    await this.deleteByPeriodFromDexieDB();
  }

  async deleteByPeriodFromDexieDB(): Promise<void> {
    const period = this.dateService.currentDateMinusDays(PERIOD);

    await this.logRepository.deleteBellow('date', period);
  }

  async deleteBetweenDateFromDexieDB(
    startDate: Date,
    endDate: Date,
  ): Promise<void> {
    await this.logRepository.deleteBetween('date', startDate, endDate);
  }
}
