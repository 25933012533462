import { IFlashStorageRepository } from './flash-storage-repository';
import { FlashStorageRepositoryImpl } from './flash-storage-repository-impl';

let flashStorageRepository: IFlashStorageRepository;

export function makeFlashStorageRepository() {
  if (!flashStorageRepository) {
    flashStorageRepository = new FlashStorageRepositoryImpl();
  }
  return flashStorageRepository;
}
