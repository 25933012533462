/* eslint-disable @typescript-eslint/no-explicit-any */
export enum CustomErrorType {
  GENERIC = 'Generic Error',
  INVALID_CONTENT = 'Invalid Content',
  NOT_FOUND = 'Not Found',
  ALREADY_EXISTS = 'Already Exists',
  UNAUTHORIZED = 'Unauthorized',
  UNAUTHENTICATED = 'Unauthenticated',
}

export class CustomError {
  private trace = '';
  private errors: string[] = [];
  private tecnicalErrors: string[] = [];
  public status: number;

  constructor(public type: CustomErrorType) {}

  /**
   * Process an exception to CustomError
   * @param trace of your error. Ex "MyUseCase.handler()"
   * @param error to process
   * @returns instance of CustomError
   */
  static handleError(trace: string, error: any): CustomError {
    if (error instanceof CustomError) {
      return error.addTrace(trace);
    } else {
      return CustomError.generic(error).addTrace(trace);
    }
  }

  static generic(error: any = [], status?: number) {
    return new CustomError(CustomErrorType.GENERIC)
      .addTecnicalError(error)
      .addStatus(status);
  }

  static invalidContent(errors: string[]): CustomError {
    return new CustomError(CustomErrorType.INVALID_CONTENT).setErrors(errors);
  }

  static notFound(errors: string[]): CustomError {
    return new CustomError(CustomErrorType.NOT_FOUND).setErrors(errors);
  }

  static unauthorized(errors: string[]): CustomError {
    return new CustomError(CustomErrorType.UNAUTHORIZED).setErrors(errors);
  }

  static unauthenticated(errors: string[]): CustomError {
    return new CustomError(CustomErrorType.UNAUTHENTICATED).setErrors(errors);
  }

  addTrace(trace: string): CustomError {
    this.trace = this.trace ? `${trace} -> ${this.trace}` : trace;
    return this;
  }

  setErrors(errors: string[]): CustomError {
    if (errors) this.errors = errors;
    return this;
  }

  addError(error: string): CustomError {
    if (error) this.errors.push(error);
    return this;
  }

  addTecnicalError(error: any): CustomError {
    if (error) {
      if (error instanceof Error) {
        this.tecnicalErrors.push(error.stack);
      } else {
        this.tecnicalErrors.push(JSON.stringify(error));
      }
    }
    return this;
  }

  addStatus(status: number): CustomError {
    if (status) this.status = status;
    return this;
  }

  getPublicError() {
    return {
      type: this.type,
      errors: this.errors,
    };
  }

  getTecnicalError(): string {
    let log = `<<<\nERROR [${this.type}] ${this.trace}`;
    if (this.tecnicalErrors.length) {
      log += '\n' + JSON.stringify(this.tecnicalErrors, null, 2);
    }
    if (this.errors.length) {
      log += '\n' + JSON.stringify(this.errors, null, 2);
    }
    return `${log}\n>>>`;
  }
}
