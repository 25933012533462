import { makeEnvironment } from '../../../../environments/make-environment';
import { makeKeyValueRepository } from '../../../repositories/key-value/key-value-repository-factory';
import { makeJwtService } from '../../common/jwt/jwt-service-factory';
import { makeLogService } from '../../common/log/log/log-service-factory';
import { IGetMediasService } from './get-medias-service';
import { GetMediasServiceImpl } from './get-medias-service-impl';

export function makeGetMediasService(): IGetMediasService {
  return new GetMediasServiceImpl(
    makeLogService(),
    makeEnvironment(),
    makeKeyValueRepository(),
    makeJwtService(),
  );
}
