/* eslint-disable @typescript-eslint/no-explicit-any */

import Dexie from 'dexie';
import { makeEnvironment } from '../../../environments/make-environment';
import { RepositoryKey } from '../../../models/common/repository/repository-key';
import { IIndexedDB } from '../core/indexed-db';
import { IKeyValueRepository } from './key-value-repository';

export class KeyValueRepositoryImpl implements IKeyValueRepository {
  dexie: Dexie;
  table: Dexie.Table;
  environment = makeEnvironment();

  constructor(private indexedDb: IIndexedDB) {
    this.dexie = this.indexedDb.getDB();
    this.table = this.dexie.table('key-value');
  }

  async set(key: RepositoryKey, value: any): Promise<void> {
    await this.table.put(value, key);
  }

  async get(key: RepositoryKey): Promise<any> {
    return await this.table.get(key);
  }

  async delete(key: RepositoryKey): Promise<void> {
    await this.table.delete(key);
  }

  async deleteAllKeys(): Promise<void> {
    await this.table.clear();
  }
}
