import { makeIndexedDB } from '../core/indexed-db-factory';
import { IKeyValueRepository } from './key-value-repository';
import { KeyValueRepositoryImpl } from './key-value-repository-impl';

let keyValueRepository: IKeyValueRepository;

export function makeKeyValueRepository() {
  if (!keyValueRepository) {
    keyValueRepository = new KeyValueRepositoryImpl(makeIndexedDB());
  }
  return keyValueRepository;
}
