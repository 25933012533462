import { makeKeyValueRepository } from '../../../repositories/key-value/key-value-repository-factory';
import { makeLogService } from '../../common/log/log/log-service-factory';
import { IConfigService } from './config-service';
import { ConfigServiceImpl } from './config-service-impl';

let configService: IConfigService;

export function makeConfigService() {
  if (!configService) {
    configService = new ConfigServiceImpl(
      makeLogService(),
      makeKeyValueRepository(),
    );
  }
  return configService;
}
