import Dexie from 'dexie';
import { AppEnvironment } from '../../../models/common/environment/environment';
import { ILogService } from '../../services/common/log/log-service';
import { IFlashStorageRepository } from '../flash-storage/flash-storage-repository';
import { IIndexedDB } from './indexed-db';
import { IndexedDBStores } from './indexed-db-stores';

export class IndexedDBImpl implements IIndexedDB {
  protected static dexie: Dexie;
  private readonly DB_VERSION_KEY = 'DB_VERSION_KEY';

  constructor(
    private env: AppEnvironment,
    private flashStorage: IFlashStorageRepository,
    private logService: ILogService,
  ) {}

  getDB() {
    try {
      if (!IndexedDBImpl.dexie) {
        IndexedDBImpl.dexie = new Dexie(`${this.env.PROJECT_NAME}`);
        (async () => {
          await this.logService.info('dexie has been instanciated');
        })();

        const dbVersion = this.checkDbVersion(IndexedDBImpl.dexie.verno);
        IndexedDBImpl.dexie.version(dbVersion).stores(IndexedDBStores);
      }

      IndexedDBImpl.dexie.open();
    } catch (error) {
      (async () => {
        await this.logService.error('Error on init Dexie', error);
      })();
    }

    return IndexedDBImpl.dexie;
  }

  private getProjectVersion() {
    return this.env.PROJECT_VERSION;
  }

  getSavedDbVersion() {
    const savedVersionString = this.flashStorage.get(this.DB_VERSION_KEY);
    return savedVersionString
      ? JSON.parse(savedVersionString)
      : { projectVersion: '', dbVersion: Date.now() };
  }

  private checkDbVersion(version: number) {
    const notSettedDbVersion = 0;
    const projectVersion = this.getProjectVersion();
    const savedDbVersion = this.getSavedDbVersion();

    if (
      savedDbVersion.projectVersion !== projectVersion ||
      (version > notSettedDbVersion && savedDbVersion.dbVersion != version)
    ) {
      savedDbVersion.projectVersion = projectVersion;
      savedDbVersion.dbVersion = Date.now();

      this.flashStorage.set(
        this.DB_VERSION_KEY,
        JSON.stringify(savedDbVersion),
      );
    }

    return savedDbVersion.dbVersion;
  }
}
