import { makeKeyValueRepository } from '../data/repositories/key-value/key-value-repository-factory';
import { makeLogService } from '../data/services/common/log/log/log-service-factory';
import { makeVolatileLogService } from '../data/services/common/log/volatile-log/volatile-log-service-factory';
import { makeTranslateService } from '../data/services/common/translate/translate-service-factory';
import { makeConfigService } from '../data/services/configs/config/device-config-service-factory';
import { makeEnvironment } from '../environments/make-environment';
import { LogLevel } from '../models/common/log-level/log-level';
import { RepositoryKey } from '../models/common/repository/repository-key';

export async function setup() {
  const environment = makeEnvironment();
  const logService = makeLogService();
  const volatileLogSerice = makeVolatileLogService();
  logService.setLogLevel(LogLevel.INFO);
  volatileLogSerice.setLogLevel(LogLevel.INFO);

  const keyValueRepository = makeKeyValueRepository();
  const translateService = makeTranslateService();

  const location = await keyValueRepository.get(RepositoryKey.LOCATION);

  await translateService.init(location || 'pt-BR');

  try {
    const configService = makeConfigService();
    await configService.load();
  } catch (error) {
    logService.error('mainSetup -> configService.load()', error);
  }
  window.document.title =
    environment.PROJECT_NAME + ' - ' + environment.fullVersion;
}
