import { makeKeyValueRepository } from '../../repositories/key-value/key-value-repository-factory';
import { makeEventService } from '../common/event/event-service-factory';
import { makeLogService } from '../common/log/log/log-service-factory';
import { IIdleService } from './idle-service';
import { IdleServiceImpl } from './idle-service-impl';

export function makeIdleService(): IIdleService {
  return new IdleServiceImpl(
    makeLogService(),
    makeKeyValueRepository(),
    makeEventService(),
  );
}
