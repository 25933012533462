import { Medias } from '../../../models/media/media/medias';
import { IIndexedDB } from '../core/indexed-db';
import { DynamicRepositoryImpl } from '../dynamic/dynamic-repository-impl';
import { IBannerTopRepository } from './banner-top-repository';

export class BannerTopRepositoryImpl
  extends DynamicRepositoryImpl<Medias>
  implements IBannerTopRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'banner-top');
  }
}
