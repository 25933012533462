import { IKeyValueRepository } from '../../../../data/repositories/key-value/key-value-repository';
import { ILogService } from '../../../../data/services/common/log/log-service';
import { IConfigService } from '../../../../data/services/configs/config/config-service';
import { CustomError } from '../../../../helpers/error/custom-error';
import { RepositoryKey } from '../../../../models/common/repository/repository-key';
import { DeviceConfig } from '../../../../models/configs/device-config/device-config';
import { GlobalConfig } from '../../../../models/configs/global-config/global-config';

export class ConfigServiceImpl implements IConfigService {
  public globalConfig: GlobalConfig;

  constructor(
    private logService: ILogService,
    private keyValueRepository: IKeyValueRepository,
  ) {}

  async save(globalConfig: GlobalConfig): Promise<void> {
    try {
      this.logService.info('ConfigServiceImpl.save -> start');
      this.logService.debug('ConfigServiceImpl.save -> request', globalConfig);

      await this.keyValueRepository.set(
        RepositoryKey.GLOBAL_CONFIG,
        globalConfig,
      );
    } catch (error) {
      throw CustomError.handleError('ConfigServiceImpl.save -> error', error);
    } finally {
      this.logService.info('ConfigServiceImpl.save -> end');
    }
  }

  async load(): Promise<void> {
    try {
      this.logService.info('ConfigServiceImpl.load -> start');

      this.globalConfig = await this.keyValueRepository.get(
        RepositoryKey.GLOBAL_CONFIG,
      );

      if (!this.globalConfig) {
        this.logService.warn(
          'As configurações do terminal não foram encontradas',
        );
      }
    } catch (error) {
      throw CustomError.handleError('ConfigServiceImpl.load -> error', error);
    } finally {
      this.logService.info('ConfigServiceImpl.load -> end');
    }
  }

  getEntityId(): string {
    try {
      this.logService.info('ConfigServiceImpl.getEntityId -> start');

      return this.globalConfig?.entityId;
    } catch (error) {
      throw CustomError.handleError(
        'ConfigServiceImpl.getEntityId -> error',
        error,
      );
    } finally {
      this.logService.info('ConfigServiceImpl.getEntityId -> end');
    }
  }

  getDeviceConfig(): DeviceConfig {
    try {
      this.logService.info('ConfigServiceImpl.getDeviceConfig -> start');

      return this.globalConfig?.deviceConfig;
    } catch (error) {
      throw CustomError.handleError(
        'ConfigServiceImpl.getDeviceConfig -> error',
        error,
      );
    } finally {
      this.logService.info('ConfigServiceImpl.getDeviceConfig -> end');
    }
  }

  getGlobalConfig(): GlobalConfig {
    try {
      this.logService.info('ConfigServiceImpl.getGlobalConfig -> start');

      return this.globalConfig;
    } catch (error) {
      throw CustomError.handleError(
        'ConfigServiceImpl.getGlobalConfig -> error',
        error,
      );
    } finally {
      this.logService.info('ConfigServiceImpl.getGlobalConfig -> end');
    }
  }
}
