import { ICookieService } from '../../../data/services/common/cookie/cookie-service';
import { ILogService } from '../../../data/services/common/log/log-service';
import { IReloadCookiesService } from '../../../data/services/reload-cookies/reload-cookies/reload-cookies-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { CookieKey } from '../../../models/common/cookie-key/cookie-key';
import { RepositoryKey } from '../../../models/common/repository/repository-key';
import { IKeyValueRepository } from './../../../data/repositories/key-value/key-value-repository';
import { IJwtService } from './../../../data/services/common/jwt/jwt-service';
import { AppEnvironment } from './../../../models/common/environment/environment';

export class ReloadCookiesUseCase {
  constructor(
    private logService: ILogService,
    private cookieService: ICookieService,
    private keyValueRepository: IKeyValueRepository,
    private environment: AppEnvironment,
    private jwtService: IJwtService,
    private reloadCookies: IReloadCookiesService,
  ) {}

  async handle(): Promise<boolean> {
    try {
      await this.logService.info('ReloadCookiesUseCase -> start');

      const auth_token = await this.getAuthToken();
      const refresh_token = await this.getRefreshToken();

      if (!auth_token && !refresh_token) {
        await this.logService.info(
          'GetMediasUseCase -> start --> invalid auth token',
        );
        return false;
      }

      const now = Math.floor(Date.now() / 1000);

      const dataAuthToken = this.jwtService.getDataFromToken(auth_token);
      const dataRefreshToken = this.jwtService.getDataFromToken(refresh_token);

      const isAuthExpired = dataAuthToken.exp - 600 < now;
      const isRefreshValid = dataRefreshToken.exp > now;

      if (isAuthExpired && !isRefreshValid) {
        await this.logService.info(
          'GetMediasUseCase -> start --> auth token and refresh token expired',
        );
        return false;
      }

      if (isAuthExpired && isRefreshValid) {
        await this.logService.info(
          'GetMediasUseCase -> start --> reload token',
        );
        const token = await this.reloadCookies.reloadCookies(refresh_token);
        await this.keyValueRepository.set(RepositoryKey.AUTH_TOKEN, token);
      }

      await this.setCookies();
      return true;
    } catch (error) {
      throw CustomError.handleError('ReloadCookiesUseCase', error);
    } finally {
      await this.logService.info('ReloadCookiesUseCase -> end');
    }
  }

  private async setCookies() {
    this.cookieService.set(
      CookieKey.DEVICE_ACCESS_TOKEN,
      await this.getAuthToken(),
      {
        domain: this.environment.getDomain,
      },
    );
    this.cookieService.set(
      CookieKey.DEVICE_REFRESH_TOKEN,
      await this.getRefreshToken(),
      {
        domain: this.environment.getDomain,
      },
    );
  }

  private async getAuthToken(): Promise<string> {
    return await this.keyValueRepository.get(RepositoryKey.AUTH_TOKEN);
  }

  private async getRefreshToken(): Promise<string> {
    return await this.keyValueRepository.get(RepositoryKey.REFRESH_TOKEN);
  }
}
