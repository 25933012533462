import { IRouterService } from './router-service';
import { RouterServiceImpl } from './router-service-impl';

let routerService: IRouterService;

export function makeRouterService() {
  if (!routerService) {
    routerService = new RouterServiceImpl();
  }
  return routerService;
}
