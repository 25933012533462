/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { makeEventService } from '../../data/services/common/event/event-service-factory';
import { makeLogService } from '../../data/services/common/log/log/log-service-factory';
import { LanguageKey } from '../../main/translate/language-key/language-key';
import { EventTopic } from '../../models/common/event-topic/event-topic';

@Component({
  template: '',
})
export class SuperComponent implements OnDestroy {
  public languageKey = LanguageKey;

  protected eventsRef: Subscription[] = [];

  protected changeDetectorRef: ChangeDetectorRef;
  protected logService = makeLogService();
  protected eventService = makeEventService();

  constructor(injector: Injector) {
    this.changeDetectorRef = injector.get(ChangeDetectorRef);

    this.eventsRef.push(
      this.eventService.subscribe(EventTopic.LANGUAGE_CHANGED, () => {
        this.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.eventsRef.forEach((e) => e?.unsubscribe());
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  subscribe(topic: EventTopic, callback: (value?: any) => void) {
    this.eventsRef.push(
      this.eventService.subscribe(topic, (value?: any) => callback(value)),
    );
  }
}
