export enum EventTopic {
  ADD_SINGLE_ITEM_TO_CART = 'add-single-item-to-cart',
  CART_SUBTOTAL_CHANGED = 'cart-subtotal-changed',
  CLEAR_TOASTS = 'clear-toasts',
  TEXT_FORWARD_CHANGED = 'text-forward-changed',
  LANGUAGE_CHANGED = 'language-changed',
  ORDER_STATUS_CHANGED = 'order-status-changed',
  PAYMENT_STATUS_CHANGED = 'payment-status-changed',
  TO_DO_LIST_CHANGED = 'to-do-list-changed',
  TO_DO_ITEM_TO_EDIT = 'to-do-item-to-edit',
  USER_ITERACTED_WITH_THE_APP = 'user-itereacted-with-the-app',
}
